import { useCallback, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MapAction from "./components/MapAction/MapAction";
import './MapPage.scss';
import { useSelector } from "react-redux";
import authService from "../../service/authService";
import MapEl from "./components/MapEl/MapEl";


const service = new authService()
const aContr = new AbortController()
const signal = aContr.signal

const MapPage = () => {
  const {token} = useSelector(s => s)
  const [searchItem, setSearchItem] = useState(null)
  const [list, setList] = useState([])
  const [all, setAll] = useState([])
  const [isShowPanel, setIsShowPanel] = useState(false)
  const [relList, setRelList] = useState([])

  const initList = useCallback(() => {
    setIsShowPanel(false)
    setSearchItem(null)
    if(token) {

      service.getMapSearchResults(token, '', signal).then(res => {
        if(res?.graves?.length > 0 && list?.length === 0)  {
          setList(res?.graves)
          setAll(res?.graves)
        }
      })
    }
  }, [token])

  useEffect(() => {
    initList()
  }, [token])

  const onSearch = (item, graves, attached) => {
    aContr && aContr.abort()
    setSearchItem(item)
    setList(graves)
    setRelList(attached)
    setIsShowPanel(true)
  } 


  const updateList = () => {
    if(token && searchItem) {
      service.getMapSearchResults(token, searchItem?.UserName).then(res => {
        if(res?.graves?.length > 0) setList(res?.graves)
        if(res?.attached_people?.length > 0) setRelList(res?.attached_people)
        setIsShowPanel(true)
      })
    }
  }

  return (
    <div className="MapPage">
      <Header/>

        <div className="MapPage__in">
          <div className="MapPage__body main">
            <div className="container">
              <h2 className="MapPage__title block_title">Карта</h2>
              <MapAction  
                onSearch={onSearch}
                showAll={() => initList()}
                />
            </div>
            <MapEl
              updateList={updateList}
              list={list}
              isShowPanel={isShowPanel}
              relList={relList}
              />
          </div>
        </div>
      
    </div>
  )
}

export default MapPage;