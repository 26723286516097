import './MapAction.scss';
import { useEffect, useState } from 'react';
import Button from '../../../../components/Button/Button';
import InputB from '../../../../components/InputB/InputB';
import { useDebounce } from 'usehooks-ts';
import authService from '../../../../service/authService';
import { useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { PulseLoader } from 'react-spinners';



const service = new authService()


const MapAction = ({
  onSearch,
  showAll
}) => {
  const { token } = useSelector(s => s)
  const [value, setValue] = useState('')
  const debValue = useDebounce(value, 2000)
  const [resultList, setResultList] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isChanging, setIsChanging] = useState(false)
  const [graves, setGraves] = useState([])
  const [attached, setAttached] = useState([])

  const getResults = () => {
    if (token && debValue?.length > 2) {
      setIsLoading(true)
      if(isChanging) {
        setIsOpen(true)
      }
      service.getMapSearchResults(token, debValue).then(res => {
        setResultList(res?.users)
        setGraves(res?.graves)
        setAttached(res?.attached_people)

        if(res?.users?.length === 0) setIsOpen(false)
        
      }).finally(() => setIsLoading(false))
    } else {
      showAll && showAll()
    }
  } 

  useEffect(() => {
    getResults()
  }, [debValue])

  useEffect(() => {
    if (!isChanging) {
      setIsOpen(false)
    }
  }, [resultList, isChanging])

  return (
    <div className='MapAction'>
      <div className="MapAction__in">
        <div className="MapAction__input">
          <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            <>
              <InputB
                placeholder={'Введите номер телефона или ФИО'}
                value={value}
                onChange={e => {
                  setValue(e.target.value)
                  setIsChanging(true)
                }}
              />
              {
                isOpen && (
                  <div className="MapAction__result">
                    {
                      isLoading ? (
                        <div className="MapAction__result_loader">
                          <PulseLoader color='var(--blue)' />
                        </div>
                      ) : (
                        resultList?.length > 0 ? (
                          resultList?.map(i => (
                            <div className="MapAction__result_item" onClick={() => {
                              onSearch(i, graves.filter(f => f?.master_user == i?.ID), attached?.filter(f => f?.master_user == i?.ID))
                              setValue(i?.UserName)
                              setIsChanging(false)
                            }}>
                              {i?.UserName}
                            </div>
                          ))
                        ) : (
                          <div className="MapAction__result_item">
                            Не найдено
                          </div>
                        )
                        
                      )
                    }
                  </div>
                )
              }
            </>
          </OutsideClickHandler>

        </div>
      </div>
    </div>
  )
}

export default MapAction;