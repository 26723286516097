import './MapPanel.scss';
import {Row, Col, message} from 'antd'
import InputB from '../../../../../../components/InputB/InputB';
import Button from '../../../../../../components/Button/Button';
import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

const coordReg = /(\+|\-|)(([0-8]\d?)(\.\d+)?|90(\.0+)?)\,(\+|\-|)((\d?\d|1[0-7]\d)(\.\d+)?|180(\.0+)?)/

const MapPanel = ({
  selectedPoint,
  setSelectedPoint,
  graves,
  list,
  setLocalList,
  openAddModal
}) => {
  const [value, setValue] = useState('')
  const [findList, setFindList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if(selectedPoint) {
      setValue(selectedPoint?.lat + ',' + selectedPoint?.lng)
    } else setValue('')
  }, [selectedPoint])

  const onSubmit = () => {
    if(value && value.match(coordReg)) {
      setSelectedPoint({lat: value?.split(',')[0], lng: value?.split(',')[1]})
      openAddModal()
    } else {
      message.error('Некорректные координаты')
    }
  }
  

  return (
    <div className='MapPanel'>
      <div className="MapPanel__in">
      <Row gutter={[20,20]}>
        <Col span={12}>
          <div className="MapPanel__part">
            <OutsideClickHandler
              onOutsideClick={() => setIsOpen(false)}
              >
              <div className="MapPanel__part_input">
                <InputB
                  placeholder={'Введите ФИО родственника'}
                  onChange={(e) => {
                    setIsOpen(true)
                    if(e?.target.value?.length === 0) setLocalList(list)
                    setSearchValue(e.target.value)
                    setFindList(list.filter(i => i?.Name?.toLowerCase().includes(e.target.value?.toLowerCase())))
                  }}
                  value={searchValue}
                  />
                  {
                    isOpen && (
                      findList?.length > 0 ? (
                        <div className="MapPanel__part_input_result">
                          {
                            findList?.map(i => (
                              <div className="MapPanel__part_input_result_item" onClick={() => {
                                setLocalList([i])
                                setIsOpen(false)
                                setSearchValue(i?.Name)
                              }}>
                                {i?.Name}
                              </div>
                            ))
                          }
                        </div>
                      ) : (
                        <div className="MapPanel__part_input_result">
                          <div className="MapPanel__part_input_result_item">
                            Не найдено
                          </div>
                        </div>
                      )
                    )
                  }
              </div>
            </OutsideClickHandler>
          </div>
        </Col>
        <Col span={12}>
          <div className="MapPanel__part MapPanel__part--last">
            <div className="MapPanel__part_action">
              <Button
                text={'Поставить метку'}
                disabled={!value}
                onClick={onSubmit}
                />
            </div>
            <div className="MapPanel__part_input">
              <InputB
                placeholder={'Координаты'}
                // readOnly={true}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value?.replace(/\s/g,''))
                }}
                />
            </div>
          </div>
        </Col>
      </Row>
      </div>
    </div>
  )
}

export default MapPanel;