import moment from 'moment';
import './AddModalItem.scss';
import {Col} from 'antd';


const AddModalItem = ({data, onClick, isSelected}) => {
  const {
    Biography,
    DateOfBirth,
    DateOfDeath,
    GraveLattitude,
    GraveLongitude,
    ID,
    MainPicture,
    Name,
    Role,
    RootID,
    RootIDforID,
    isActive,
    master_user,
  } = data || {}

  return (
    <Col span={24}>
      <div className={'AddModalItem' + (isSelected ? ' active ' : '')} onClick={() => onClick(s => s?.ID == ID ? null : data)}>
        <div className="AddModalItem__in">
          <div className="AddModalItem__role">
            {Role} 
          </div>
          <div className="AddModalItem__name">
            {Name}
          </div>
          <div className="AddModalItem__date">
            {moment(DateOfBirth).format('DD-MM-YYYY')} - {moment(DateOfDeath).format('DD-MM-YYYY')}
          </div>
        </div>
      </div>
    </Col>
  )
}

export default AddModalItem;