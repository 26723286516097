import { Modal, Row, Col, message } from 'antd';
import './GraveModal.scss';
import Button from '../../../../components/Button/Button';
import moment from 'moment';
import { useSelector } from 'react-redux';
import authService from '../../../../service/authService';
import { useState } from 'react';

const service = new authService()

const GraveModal = (props) => {
  const {data,updateList, onCancel, ...modalProps} = props || {}
  const {token} = useSelector(s => s)
  const [load, setLoad] = useState(false)

  const onDelete = () => {
    if(token && data) {
      setLoad(true)
      service.mapDeletePoint(token, {
        user_id: data?.master_user,
        relative_id: data?.ID
      }).then(res => {
        if(res?.error === 0) {
          updateList()
          onCancel && onCancel()
        }
        if(res?.error === 1) {
          message.error('Не удалось удалить метку')
        }
      }).finally(() => setLoad(false))
    }
  }

  return (
    <Modal
      {...modalProps}
      width={615}
      onCancel={onCancel}
      className='modal GraveModal'
      >
      <Row gutter={[20,20]}>
        <Col span={24}>
          <div className='GraveModal__head'>
            <h3 className='GraveModal__head_title'>
              {data?.Name}
            </h3>
            <div className="GraveModal__head_date">
              {moment(data?.DateOfBirth).format('DD.MM.YYYY')} - {moment(data?.DateOfDeath).format('DD.MM.YYYY')}
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className='GraveModal__item'>
            <div className="GraveModal__item_label">
              Дата рождения
            </div>
            <div className="GraveModal__item_value">
              {moment(data?.DateOfBirth).format('DD.MM.YYYY')}
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className='GraveModal__item'>
            <div className="GraveModal__item_label">
              Дата смерти
            </div>
            <div className="GraveModal__item_value">
              {moment(data?.DateOfDeath).format('DD.MM.YYYY')}
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className='GraveModal__item'>
            <div className="GraveModal__item_label">
              Координаты могилки
            </div>
            <div className="GraveModal__item_value">
              {data?.GraveLattitude}, {data?.GraveLongitude}
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className='GraveModal__item'>
            <div className="GraveModal__item_label">
              Номер с-ва о смерти и дата выдачи (если есть)
            </div>
            <div className="GraveModal__item_value">
              -
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className="GraveModal__action">
            <Button
              variant={'danger'}
              text={'Удалить метку'}
              load={load}
              disabled={!data}
              onClick={onDelete}
              />
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default GraveModal;