import {Modal, Row, Col, message} from 'antd';
import './AddModal.scss';
import InputB from '../../../../components/InputB/InputB';
import Button from '../../../../components/Button/Button';
import { useEffect, useState } from 'react';
import AddModalItem from './components/AddModalItem/AddModalItem';
import { useSelector } from 'react-redux';
import authService from '../../../../service/authService';

const service = new authService()


const AddModal = (props) => {
  const {
    relList = [],
    onCancel,
    selectedPoint,
    updateList,
    ...otherProps
  } = props
  const {token} = useSelector(s => s)
  const [searchList, setSearchList] = useState([])
  const [value, setValue] = useState('')
  const [selectedPeople, setSelectedPeople] = useState(null)

  const [load, setLoad] = useState(false)

  useEffect(() => {
    if(!value && relList?.length > 0) {
      setSearchList(relList)
    } 
    if(value && relList?.length > 0) {
      setSearchList(relList?.filter(i => i?.Name?.toUpperCase()?.includes(value?.toUpperCase())))
    }
  }, [value, relList])

  const onClose = () => {
    setValue('')
    setSelectedPeople(null)
    onCancel && onCancel()
  }

  const onSubmit = () => {
    if(token) {
      setLoad(true)
      service.mapAddPoint(token, {
        user_id: selectedPeople?.master_user,
        relative_id: selectedPeople?.ID ,
        lattitude: selectedPoint?.lat,
        longitude: selectedPoint?.lng
      })
      .then(res => {
        if(res?.error === 0) {
          updateList()
          onClose()
        }
        if(res?.error === 1) {
          message.error('Не удалось добавить метку')
          console.log(res)
        }

      })
      .finally(() => {
        setLoad(false)
      })
    }
  }

  return (
    <Modal
      {...otherProps}
      onCancel={onClose}
      className='modal AddModal'
      width={615}
      >
      <Row gutter={[30,30]}>
        <Col span={24}>
          <div className='AddModal__head'>
            <h3 className="AddModal__head_title">Добавление метки на карте</h3>
            <div className="AddModal__head_sub">Выберете родственника из списка</div>
          </div>
        </Col>
        <Col span={24}>
          <div className="AddModal__search">
            <div className="AddModal__search_input">
              <InputB
                placeholder={'Поиск по имени...'}
                value={value}
                onChange={e => setValue(e.target.value)}
                />
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className="AddModal__body">
            <Row gutter={[7,7]}>
            {
              searchList?.map(i => (
                <AddModalItem
                  data={i}
                  onClick={setSelectedPeople}
                  isSelected={selectedPeople?.ID == i?.ID}
                  key={i?.ID}
                  />
              ))
            }
            </Row>
            
          </div>
        </Col>
        <Col span={24}>
          <div className='AddModal__action'>
            <Button
              text={'Сохранить'}
              variant={'primary'}
              load={load}
              onClick={onSubmit}
              disabled={!selectedPeople}
              />
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default AddModal;