import './MapEl.scss';
import icon from '../../../../assets/img/geo.svg';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';

import AddModal from '../AddModal/AddModal';
import GraveModal from '../GraveModal/GraveModal';
import { useEffect, useCallback, useState } from 'react';
import MapPanel from './components/MapPanel/MapPanel';

const MapEl = ({
  list = [],
  isShowPanel,
  relList = [],
  updateList
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyD2ZThk86dts58cFNmsIGowRmLQ6g4BvHE'
  })
  const [center, setCenter] = useState({
    lat: 55.4523827821331,
    lng: 37.7280227524225
  })
  const [_, setGraveModal] = useState(false)
  const [map, setMap] = useState(null)
  
  const [localList, setLocalList] = useState([])
  const [selectedPoint, setSelectedPoint] = useState()
  const [selectedMarker, setSelectedMarker] = useState(null)

  const [addPointModal, setAddPointModal] = useState(false)


  useEffect(() => {
    setLocalList(list)
  }, [list])


  const onLoad = useCallback(function callback(map) {
    if(center) {
      setMap(map)
    }
    
  }, [center])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => {
    if(map && localList?.length > 0) {
      setCenter({lat: Number(localList[0]?.GraveLattitude), lng: Number(localList[0]?.GraveLongitude)})
    }
  }, [map, localList])

  return (
    <div className='MapEl'>
      <GraveModal
        data={selectedMarker}
        onCancel={() => {
          setGraveModal(false)
          setSelectedMarker(null)
        }}
        open={selectedMarker}
        updateList={updateList}
        />
      <AddModal
        open={addPointModal}
        onCancel={() => setAddPointModal(false)}
        relList={relList}
        selectedPoint={selectedPoint}
        updateList={updateList}
        />

      {
        (list?.length > 0 && isShowPanel) && (
          <MapPanel
            selectedPoint={selectedPoint}
            list={list}
            setLocalList={setLocalList}
            setSelectedPoint={setSelectedPoint} 
            openAddModal={() => setAddPointModal(true)}
            />
        )
      }
      {
        isLoaded && (
          <GoogleMap
            mapContainerClassName='MapEl__map'
            center={center}
            zoom={9}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={(e) => {
              setSelectedPoint({lat:e.latLng.lat(), lng:e.latLng.lng()})
            }}
            >
            {
              localList?.map(i => (
                <Marker
                  onClick={(e) => {
                    setSelectedMarker(i)
                    setGraveModal(true)
                  }}
                  position={{lat: Number(i?.GraveLattitude), lng: Number(i?.GraveLongitude)}}
                  icon={icon}
                  />
              ))
            }
          </GoogleMap>
        )
      }
    </div>
  )
}

export default MapEl;